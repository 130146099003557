(function ($) {

var $ua = navigator.userAgent.toUpperCase();
var $ua_sp = ($ua.indexOf('IPHONE') != -1) || ($ua.indexOf('ANDROID') != -1 || ($ua.indexOf('IPAD') != -1));
var $ua_iphone = ($ua.indexOf('IPHONE') != -1);
var $ua_ipad = ($ua.indexOf('IPAD') != -1);
var $ua_android = ($ua.indexOf('ANDROID') != -1);
var $breakPoint = 768;
var $flagNarrow = $(window).width() < 768;
var $browser;
var $nowPlaying = '';
var $flagIntro = true;
var self = $.hero = {



	init: function () {

		$(window).ready(function () {
			$('.hero').height($(window).height());
			$('.hero-video').height($(window).height());
			$('.hero-video video').height($(window).height());
			$('.hero-world').height($(window).height());
			$('.hero-japan').height($(window).height());
		});

		$(window).load( function () {
			$('.hero').addClass('inView');
			$('.js-trigger').addClass('inView');
		});
		if ($ua_sp) {
			$('.hero-video').remove();
		}
		else {
			self.perspective();

			function fin () {
				if ($('.hero-video')[0]) {
					self.mouse();
				}
			}

			var t = setTimeout( fin, 3000 );
		}
	},



	mouse: function () {
		var _hero = $(".hero");
		var _dir = "";

		_hero.mousemove(function(e) {
			var _x = e.clientX;
			var _y = e.clientY;
			var _w = $(this).width();
			var _h = $(this).height();
			var _area = _w / 2;

			if ((_x < _area) && ((_dir == "") || (_dir == "right"))) {
				if ($nowPlaying == 'movie02') {
					self.playVideo($("#movie02"), 'off');
				}

				_dir = "left";
				$nowPlaying = 'movie01';
				self.playVideo($("#" + $nowPlaying), 'on');
			}

			if ((_x > _area) && ((_dir == "") || (_dir == "left"))) {
				if ($nowPlaying == 'movie01') {
					self.playVideo($("#movie01"), 'off');
				}

				_dir = "right";
				$nowPlaying = 'movie02';
				self.playVideo($("#" + $nowPlaying), 'on');
			}
		});

		_hero.mouseenter(function(e) {
			var _x = e.clientX;
			var _y = e.clientY;
			var _w = $(this).width();
			var _h = $(this).height();
			var _area = _w / 2;

			if ((_x < _area) && ((_dir == "") || (_dir == "right"))) {
				if ($nowPlaying == 'movie02') {
					self.playVideo($("#movie02"), 'off');
				}

				_dir = "left";
				$nowPlaying = 'movie01';
				self.playVideo($("#" + $nowPlaying), 'on');
			}

			if ((_x > _area) && ((_dir == "") || (_dir == "left"))) {
				if ($nowPlaying == 'movie01') {
					self.playVideo($("#movie01"), 'off');
				}

				_dir = "right";
				$nowPlaying = 'movie02';
				self.playVideo($("#" + $nowPlaying), 'on');
			}
		}).mouseleave(function(){
			self.playVideo($("#movie01"), 'off');
			self.playVideo($("#movie02"), 'off');
			_dir = '';
		});
	},



	playVideo: function ($target, $mode) {
		var other = $target.parent('div').attr('class').indexOf('japan') >= 0 ? 'world' : 'japan';
				other = $('.hero-' + other);

		if ($flagIntro) {
			$('.hero-world').addClass('is-finished');
		  $('.hero-japan').addClass('is-finished');
			$flagIntro = false;
		}

		if ($mode == 'on') {
			$target.fadeIn(200);
			$target.find('video')[0].play();
			$target.parent('div').addClass('is-play');
			other.css('opacity', 0.4);
		}
		else if ($mode == 'off') {
			$target.fadeOut(200);
			$target.find('video')[0].pause();
			$target.parent('div').removeClass('is-play');
			other.css('opacity', 1);
		}
	},



	perspective: function () {
		var body = document.body;
		var frame = document.getElementById('perspectiveMask');
		var shadow = document.getElementById('shadow');
		var target = document.getElementById('perspective');
		var positionX = document.getElementById('mouse-position-x');
		var positionY = document.getElementById('mouse-position-y');

		function onMouseMove(e) {
		  var docW = body.clientWidth / 2;
		  var docH = (body.clientHeight / 2) - 600;
		  var moveX_1 = (e.clientX - docW) / 35;
		  var moveY_1 = 0;
		  var moveX_2 = (e.clientX - docW) / 40;
		  var moveY_2 = 0;

		  frame.style.webkitTransform = 'translate(' + -moveX_1 + 'px, ' + -moveY_1 + 'px)';
		  frame.style.webkitTransform += 'rotateY(' + moveX_1 + 'deg)';
			frame.style.msTransform = 'translate(' + -moveX_1 + 'px, ' + -moveY_1 + 'px)';
			frame.style.msTransform += 'rotateY(' + moveX_1 + 'deg)';

			if ($browser != 'safari') {
				shadow.style.webkitTransform = 'translate(' + -moveX_2 + 'px, ' + -moveY_2 + 'px)';
				shadow.style.webkitTransform += 'rotateY(' + moveX_2 + 'deg)';
				shadow.style.msTransform = 'translate(' + -moveX_2 + 'px, ' + -moveY_2 + 'px)';
			  shadow.style.msTransform += 'rotateY(' + moveX_2 + 'deg)';
			}
		}

		body.addEventListener('mousemove', onMouseMove, false);
	}



}
$(function () { self.init(); });
})(jQuery);
